import React from 'react'
//import { Link } from 'gatsby'
import './style.scss'
import {Navbar, Nav} from 'react-bootstrap'
import Language from "../language"
import {Link, FormattedMessage } from "gatsby-plugin-intl"
import brandLogo from 'static/brandlogo.png'

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1.2rem`, margitTop: 'auto', marginBottom:'auto' }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)
class Navi extends React.Component {
  render() {
    const { location, title } = this.props
    return (

  <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="fixed-top">
  <Navbar.Brand href="/">
  <img
        alt=""
        src={brandLogo}
        className="d-inline-block align-top"
      /> <span className="ml-2 brandDescription"><b>Education   &  </b>  <br/><b>Life abroad</b> </span>
  
  </Navbar.Brand> 
  <Navbar.Toggle aria-controls="responsive-navbar-nav" className="custom-toggler navbar-dark"/>
  <Navbar.Collapse id="responsive-navbar-nav" className="mr-auto justify-content-end align-items-end">
    
    <Nav className="mt-auto mb-auto">
      <ListLink to="/" className="nav-link">
      <FormattedMessage id="home" />
      </ListLink>
      <ListLink to="/about/" className="nav-link">
      <FormattedMessage id="about" />
      </ListLink>
      <ListLink to="/services/" className="nav-link">
      <FormattedMessage id="services" />
      </ListLink>
      <ListLink to="/double-degree/" className="nav-link">
      <FormattedMessage id="double_degree" />
      </ListLink>
      <ListLink to="/code-of-honor/" className="nav-link">
      <FormattedMessage id="code_of_honor" />
      </ListLink>
      <ListLink to="/contact-us/" className="nav-link">
      <FormattedMessage id="contact_us" />
      </ListLink>
      <Language />
    </Nav>
  </Navbar.Collapse>
</Navbar>
    )
  }
}

export default Navi



const path = require(`path`)

module.exports = {
  siteMetadata: {
    title: 'CIGAM global',
    description: 'CIGAM provides consultation and coaching services for students who are interested in studying and living abroad',
    siteUrl: 'https://cigamglobal.com',
    author: 'Vadim Starostin',
    adsense: '',
  },
  pathPrefix: '/',
  plugins: [
    
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/posts/`,
        name: 'posts',
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        // replace "UA-XXXXXXXXX-X" with your own Tracking ID
        trackingId: "UA-141739268-1",
      },
      
    },
    {
      resolve: `gatsby-plugin-facebook-pixel`,
      options: {
        pixelId: '2298666090387085',
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/static/`,
          name: `images`,
      },
  },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.cigamglobal.com',
        policy: [{ userAgent: '*', disallow: ['/*/success', '/*/questionnaire', '/*/404', 'cigamglobal.com/en/*','cigamglobal.com/th/*'] }]
      }
    },
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: `https://www.cigamglobal.com/`,
      },
    },
    {
      resolve: `gatsby-plugin-intl`,
      options: {
        // language JSON resource path
        path: `${__dirname}/src/intl`,
        // supported language
        languages: [`en`, `th`, `ru`],
        // language file path
        //defaultLanguage: `en`,
        // option to redirect to `/en` when connecting `/`
        redirect: false,
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        output: `/sitemap.xml`,
        // Exclude specific pages or groups of pages using glob parameters
        // See: https://github.com/isaacs/minimatch
        // The example below will exclude the single `path/to/page` and all routes beginning with `category`
        exclude: ["/en/*", "/th/*", "/ru/*", "cigamglobal.com/th/", "cigamglobal.com/en/", "cigamglobal.com/ru/"],

        query: `
          {
            site {
              siteMetadata {
                siteUrl
              }
            }
  
            allSitePage {
              edges {
                node {
                  path
                }
              }
            }
        }`
      }
    },
    {
      resolve: `gatsby-plugin-typography`,
      options: {
        pathToConfigModule: `src/utils/typography`,
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            
          },
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants',
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `CIGAM global`,
        short_name: `CIGAM`,
        start_url: `/`,
        display: `standalone`,
        icon: `static/img/brandlogo.png`,
        cache_busting_mode: `query`, // `query`(default), `name`, or `none`
      },
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-offline',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-react-next',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-twitter',
    'gatsby-transformer-sharp',
    
  ],
}

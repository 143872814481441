
 
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const CodeOfHonorPage = ({ intl }) => {

const isEnglish = () => {
return (intl.locale === "en")
}

const isThai = () => {
return (intl.locale === "th")
}

const isRussian = () => {
return (intl.locale === "ru")
}

return (

<Layout> 

<SEO
    site={siteMetadata}
    title="Code of Honor"
    description="CIGAM pledges to coach students using best practices while adhering to ethical principles in their journey to studying and living abroad."
   />

<section id="thumbnail">
<div className="center-cropped object-position-1">
  <img className="object-position-1" src={require('../images/codeofhonor1.jpeg')} alt="plan.jpeg" />
<div className="centered">
 <p id="thumbnailText"><em><FormattedMessage id="coh_we_hold" /></em></p>
</div>
</div>

</section> 
  
<section id="codeOfHonor">
<div className="container">
      
<div className="text-center mb-5 mt-5">
  
  <h1><FormattedMessage id="coh_code" /></h1>
  <h2><FormattedMessage id="coh_code_h" /> </h2>
  <p><FormattedMessage id="coh_code_p" /></p>
  
  </div>

  {/* // lazy as fuk lol ^-^ */}

  { (isEnglish(intl)) && <div id="code"> 
<ul>
<li><p>Our most valuable asset is our <strong>clients’ trust</strong> and all of our work should strengthen this trust in us.</p></li>
<li><p>We act with <strong>integrity and honesty</strong> in all matters, and are accountable for our actions. We also expect our clients to uphold the <strong>highest ethical standards</strong>.</p></li>
<li><p>We are committed to a <strong>supportive</strong> <strong>environment</strong>, where both our students and our consultants strive to reach their highest potential. </p></li>
<li><p>We believe in the <strong>value of hard work</strong> and expect students to <strong>commit</strong> to investing their <strong>full effort</strong>. </p></li>
<li><p>We <strong>honor</strong> the language and spirit of <strong>the law</strong> of every nation and undertake open and fair activities <strong>to be active global citizens</strong>.</p></li>
<li><p>We strictly limit access to and use of clients’ personal information, and require that each of us take <strong>measures to protect clients’ data</strong> from unauthorized access. </p></li>
<li><p>We <strong>never falsify</strong> any documents <strong>or distort </strong>the true nature of information.</p></li>
<li><p>We <strong>treat </strong>others <strong>with respect</strong> and <strong>value</strong> their <strong>time</strong>.</p></li></ul>
</div>} 
{ (isRussian(intl)) && <div id="code"> 
 <ul>
<li><p> Наш самый ценный актив – это <strong> доверие наших клиентов </strong> и вся наша работа должна укреплять это доверие к нам.</p></li>
<li><p> Мы<strong> действуем и поступаем честно </strong> во всех вопросах и несем полную ответственность за свои действия. Мы также ожидаем <strong> соблюдение высочайших этических норм </strong> от наших клиентов.</p></li>
<li><p> Мы<strong> стремимся к созданию благоприятной среды,</strong> в которой наши студенты и консультанты будут раскрывать свой максимально высокий уровень потенциала.</p></li>
<li><p> Мы верим в <strong> ценность тяжелой работы </strong> и ожидаем, что ученики приложат <strong> максимальные усилия</strong>.</p></li>
<li><p> Мы <strong> уважаем язык и дух закона </strong> каждой нации и предпринимаем открытые и честные действия, чтобы <strong> стать активными гражданами мира.</strong></p></li>
<li><p> Мы строго ограничиваем доступ и использование личной информации клиентов и требуем, чтобы каждый из нас принимал все возможные <strong> меры для защиты данных клиентов </strong> от несанкционированного доступа. </p></li>
<li><p> Мы <strong> никогда не подделываем </strong> какие-либо документы и не искажаем истинную природу информации.</p></li>
<li><p> Мы <strong>относимся </strong> к другим <strong> с уважением и ценим </strong> их <strong> время</strong>.</p></li></ul>
</div>}

{ (isThai(intl)) && <div id="code"> 
<ul>
<li><p><strong>ความไว้วางใจของลูกค้าคือสิ่งที่สำคัญที่สุดสำหรับซิแกม</strong> และความไว้วางใจดังกล่าวจะสะท้อนออกมาในรูปของผลงาน</p></li>
<li><p>ซิแกมยึดมั่นในความ<strong>ซื่อสัตย์สุจริต</strong>และความรับผิดชอบต่อการกระทำของตนเอง เรามุ่งหวังให้ลูกค้ายึดมั่นในหลักจริยธรรมสูงสุดเช่นกัน</p></li>
<li><p>เราจะสร้าง<strong>สภาวะแวดล้อม</strong>ที่เอื้อต่อการดึงศักยภาพของทั้งนักเรียนและที่ปรึกษาของซิแกมมาใช้ได้อย่างเต็มที่<strong>&nbsp;</strong></p></li>
<li><p>เราเห็นคุณค่าของ<strong>ความมุ่งมั่นทุ่มเท</strong> และมุ่งหวังให้นักเรียนได้<strong>พยายามอย่างสุดความสามารถ</strong></p></li>
<li><p>เรา<strong>เคารพ</strong>ถ้อยคำและเจตนารมณ์ของ<strong>กฏหมาย</strong>ในแต่ละประเทศ และเปิดกว้างให้กับกิจกรรมที่สร้างสรรค์และส่งเสริมการพัฒนาบุคลากรให้เป็นผู้มีศักยภาพ และคุณภาพสู่สังคมโลก</p></li>
<li><p>ซิแกมจะเก็บรักษาข้อมูลส่วนตัวของลูกค้าทั้งหมดเป็นความลับ และจะ<strong>ไม่เปิดเผยข้อมูลให้กับบุคคลที่สามโดยไม่ได้รับอนุญาต</strong></p></li>
<li><p>ซิแกมไม่มีนโยบายที่จะ<strong>บิดเบือน</strong>ความจริงหรือสร้างข้อมูล<strong>เท็จ</strong></p></li>
<li><p>ซิแกมให้ความสำคัญกับการให้<strong>เกียรติ</strong>ผู้อื่นและการ<strong>เคารพต่อเวลา</strong>ของผู้อื่นเสมอ</p></li></ul>
</div>}
</div>
</section>
</Layout>
)
}

export default injectIntl(CodeOfHonorPage)



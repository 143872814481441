
import React from 'react'
import './style.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(fab, faInstagram, faFacebook, faTwitter)


const Footer = () => (
  <div className="footer">
    <div className="container">
      <hr className="custom_hr" />
    </div>
    <div className="container-flex footerContainer">
      <div>
        <a href="https://www.instagram.com/cigamglobal/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={["fab", "instagram"]} size="2x"  /> 
        </a>
      </div>
      <div>
        <a href="https://facebook.com/cigamglobal" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" /> 
        </a>
      </div>
      <div>
        <a href="https://twitter.com/cigamglobal" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={["fab", "twitter"]} size="2x" />
        {/* style={{color:"#D4D4D4"}} */}
        </a>
      </div>
      <div>
        <a href="https://linkedin.com/company/cigam-global" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" /> 
        </a>
      </div>
      <div>
        <a href="https://www.youtube.com/channel/UCET6HsY5w8DvrYIJ4HJhtpw" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={["fab", "youtube"]} size="2x" /> 
        </a>
      </div>
    </div>
    <div className="row copyright-container">
        <div className="container-flex  d-flex flex-direction-row align-items-center ">
          <div className="privacy text-center ">
            <p>
              &copy; {new Date().getFullYear()} cigamglobal | all rights
              reserved
              <a href="/privacy-policy/">&nbsp;| Privacy Policy | </a>
              <a href="/terms-conditions/">&nbsp;Terms And Conditions</a>
            </p>
          </div>
        </div>
      </div>
  </div>
)

export default Footer

import React from 'react'
import './style.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(fab, faInstagram, faFacebook, faTwitter)


export default class StickyContactBar extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          isTop: true
        };
        this.onScroll = this.onScroll.bind(this);
      }
    
      componentDidMount() {
        document.addEventListener('scroll', () => {
          const isTop = window.scrollY < 100;
          if (isTop !== this.state.isTop) {
            this.onScroll(isTop);
          }
        });
      }
    
      onScroll(isTop) {
        this.setState({ isTop });
      }
      
      componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
      }
    
  render() {

    return (
        
            <div className={this.state.isTop ? 'container-flex contact-bar-container d-lg-none' : 'hidden d-none'}>
                
                <div className="contact-item-container">
                    <a href="https://www.instagram.com/cigamglobal/" target="_blank" rel="noopener noreferrer">
                    <span className="sm-icon"><FontAwesomeIcon icon={["fab", "instagram"]} size="2x" /> </span>
                    <span className="sm-label">Instagram</span>
                    </a>
                </div>
                <div className="contact-item-container">
                    <a href="https://facebook.com/cigamglobal" target="_blank" rel="noopener noreferrer">
                        <span className="sm-icon"><FontAwesomeIcon icon={["fab", "facebook"]} size="2x" /> </span>
                        <span className="sm-label">Facebook</span>
                    </a>
                </div>
                <div className="contact-item-container">
                    <a href="https://twitter.com/cigamglobal" target="_blank" rel="noopener noreferrer">
                        <span className="sm-icon"><FontAwesomeIcon icon={["fab", "twitter"]} size="2x" /></span>
                        <span className="sm-label">Twitter</span>
                    </a>
                </div>
                <div className="contact-item-container">
                    <a href="https://linkedin.com/company/cigam-global" target="_blank" rel="noopener noreferrer">
                        <span className="sm-icon"><FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" /></span>
                        <span className="sm-label">LinkedIn</span>
                    </a>
                </div>
                <div className="contact-item-container">
                    <a href="https://www.youtube.com/channel/UCET6HsY5w8DvrYIJ4HJhtpw" target="_blank" rel="noopener noreferrer">
                        <span className="sm-icon"><FontAwesomeIcon icon={["fab", "youtube"]} size="2x" /></span>
                        <span className="sm-label">Youtube</span>
                    </a>
                </div>
            </div>
        //</section>
      
    )
  }
}




import React from 'react'
import Navi from 'components/Navi'
import Footer from 'components/Footer'
import { siteMetadata } from '../../../gatsby-config'
import CTA from 'components/CTA'
import StickyContactBar from 'components/Sticky-contact-bar'


import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/custom_bootstrap.scss'
import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'


class Layout extends React.Component {
  
  render() {
    const { children } = this.props
    
    return (
      <div onScroll={this.handleScroll}>
        <Navi title={siteMetadata.title} {...this.props} />
        {children}
        <CTA />
        <StickyContactBar />
        <Footer title={siteMetadata.title} author={siteMetadata.author} />
      </div>
    )
  }
}

export default Layout

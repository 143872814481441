import React from 'react'
import './style.scss'
import Button from 'react-bootstrap/Button'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const CTA = () => (
         
         <section id="cta" className="d-flex justify-content-center">
          <div className="container-flex ctaBox">
            <h3><FormattedMessage id="cta_dream" /></h3>
            <Button variant="warning warningCustom ctaButton"><Link to="/contact-us">
            <FormattedMessage id="cta_button" /></Link>             
            </Button>
          </div>
        </section>

)
export default CTA

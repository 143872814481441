import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { siteMetadata } from '../../../gatsby-config'
import get from 'lodash/get'

function SEO({ site, description, lang, image, keywords, title, pathname, meta }) {

  const siteTitle = get(site, 'title')
  title = title ? `${title} | ${siteTitle}` : siteTitle

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || site.description
        const metaImage = image && image.src ? `${data.site.siteMetadata.siteUrl}${image.src}` : null
        const metaUrl = `${data.site.siteMetadata.siteUrl}${pathname}`
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:creator`,
                content: `@${siteMetadata.twitter}`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: 'twitter:site',
                content: `@${get(site, 'twitter')}`,
              },
              
              
              {
                property: 'og:description',
                content: get(site, 'description'),
              },
              {
                property: 'og:url',
                content: `${get(site, 'siteUrl')}/`,
              },
              
            ]
              .concat(metaImage ? [
                {
                  property: `og:image`,
                  content: metaImage
                },
                {
                  property: `og:image:alt`,
                  content: title,
                },
                {
                  property: 'og:image:width',
                  content: image.width
                },
                {
                  property: 'og:image:height',
                  content: image.height
                },
                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                }
              ] : [
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
              ])
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: [`en`, `th`],
  meta: [],
  keywords: [`CIGAM Global`,`CIGAM`, `education`, `university`, `study abroad`, `university application`, `double degree`],
  pathname: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.object,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
  title: PropTypes.string.isRequired
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        author
        
      }
    }
  }
`